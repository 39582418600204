<template>
    <section class="gen-section-padding-3 gen-single-video">
        <div class="container">
           <div class="row no-gutters">
              <div class="col-lg-12">
                 <div class="row">
                    <div class="col-lg-12">
                      <div class="pm-inner">
                        <div class="gen-more-like">
                            <h5 class="gen-more--search"  v-for="publish in cat.slice(0,1)" :key="publish.id"> التصنيف : {{ publish.name_ar }}</h5>
                            <div class="row post-loadmore-wrapper" style="margin-bottom: 5vh;">
                              <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in cat" :key="publish.id">
                              <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                  <div class="gen-movie-contain" @click="getFeed(publish)">
                                    <div class="gen-movie-img" >
                                      <a  @click="getFeed(publish)" >
                                          <img v-lazy="publish.carsoul_img" alt="single-video-image">
                                          
                                          
                                          <div class="gen-movie-action">
                                              <div  class="gen-button">
                                                  <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                                                  <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                                  <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                                                  </svg> 
                                              </div>
                                          </div>
                                          </a>
                                          <div class="gen-info-contain">
                                          <div class="gen-movie-info">
                                              <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                          </div>
                                          <div class="gen-movie-meta-holder">
                                              <ul>
                                                  <li>{{publish.year}}</li>
                                                  <li>
                                                      <a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a>
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                          <!-- <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in cat" :key="publish.id">
                                              <div class="gen-carousel-movies-style-3 movie-grid style-3">
                                                  <div class="gen-movie-contain">
                                                      <div class="gen-movie-img">
                                                          <img :src="publish.carsoul_img"
                                                              alt="single-video-image">
                                                     
                                                          <div class="gen-movie-action">
                                                              <a @click="getFeed(publish)" class="gen-button">
                                                                  <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                                                                    <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                                                    <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                                                                     </svg> 
                                                              </a>
                                                          </div>
                                                      </div>
                                                      <div class="gen-info-contain">
                                                          <div class="gen-movie-info">
                                                              <h3><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                                          </div>
                                                          <div class="gen-movie-meta-holder">
                                                              <ul>
                                                                  <li>{{ publish.year }}</li>
                                                                  <li>
                                                                      <a @click="getFeed(publish)"><span>{{ publish.name_ar }}</span></a>
                                                                  </li>
                                                              </ul>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div> -->
                                      </div>
                                  </div>
                              </div>
                          </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
  </template>
  
  <script>
  import { HTTP } from '@/Api/http-common';
  import { useCookie } from 'vue-cookie-next'
  import { ref } from '@vue/reactivity';
  import { useRouter, useRoute } from "vue-router"
  import { onMounted } from '@vue/runtime-core';
  export default {
     name: 'AppCategorys',
     props: {
         catid: String,
      },
       setup(props) {
          const router = useRouter();
          const route = useRoute();
          const cookie = useCookie()
          const cat = ref([]);
          const GetCategories = async () => {
              await HTTP.get('GetContentByCategory.php?LIMIT=50&OFFSET=0&cat_id=' + props.catid).then(res => {
                  cat.value = res.data.Content;
              })
          }
          onMounted(() => {
              GetCategories();
          });
  
          const getFeed = (publish) => {
              cookie.setCookie('cat_id', publish.cat_id);
          if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("minutes") > 0 ) {
            router.push({ name: "Contents", params: { content_id: publish.id } });
          } else {
            router.push({ name: "Subscribes" });
          }
        };
        //   const getFeed = (publish) => {
        //       cookie.setCookie('cat_id', publish.cat_id);
        //       cookie.setCookie("product_code",publish.product_code);
        //   if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("minutes") > 0 ) {
        //     router.push({ name: "Content", params: { content_id: publish.id } });
        //   } else {
        //     cookie.setCookie('content_id', publish.id);
        //     router.push({ name: "Trailer" });
        //   }
        // };
          return { cat, getFeed };
      },
  }
  </script>
  
  <style>
  
  </style>